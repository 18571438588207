import classNames from "classnames/bind";
import { Option, QuestionBlock, QuestionsText } from "./Quiz.types";
import { QuizIconTick } from "./QuizIconTick";
import { QuizIconClock } from "./QuizIconClock";
import { QuizIconSadFace } from "./QuizIconSadFace";
import styles from "./QuizQuestionScreen.module.css";
import { getImageSource } from "../../../helpers/getImageSource";
import { getContentfulImageUrl } from "../../../helpers/images/getContentfulImageUrl";
import { getStoryblokImageUrl } from "../../../helpers/images/getStoryblokImageUrl";

const cx = classNames.bind(styles);

export const QuizQuestionScreen = ({
  currentQuestionNumber: currentQuestionCount,
  totalQuestionCount,
  questionsText,
  currentQuestion,
  onClickOption,
  onClickNext,
}: {
  currentQuestionNumber: number;
  totalQuestionCount: number;
  questionsText: QuestionsText;
  currentQuestion: QuestionBlock;
  onClickOption: (option: Option) => void;
  onClickNext: () => void;
}) => {
  const imageSource = currentQuestion.correctAnswerImage?.url
    ? getImageSource(currentQuestion.correctAnswerImage.url)
    : null;

  return (
    <div className={cx("quiz-columns")}>
      <div className={cx("question-progress")}>
        <div
          className={cx("question-progress__bar")}
          style={{
            transform: `scaleX(${currentQuestionCount / totalQuestionCount})`,
          }}
        ></div>
      </div>

      <div className={cx("quiz-columns__container")}>
        <div className={cx("question-column")}>
          <div
            className={cx(
              "question-column__container",
              currentQuestion.isColumnsHidden
                ? "question-column__container--hidden"
                : "question-column__container--visible",
            )}
          >
            <h5 className={cx("question-column__count")}>
              {`${questionsText.questionKicker} ${currentQuestionCount}/${totalQuestionCount}`}
            </h5>
            <h1 className={cx("question-column__title")}>
              {currentQuestion.question}
            </h1>
            <div className={cx("question-column__timer-container")}>
              <div className={cx("question-column__timer-text")}>
                <QuizIconClock className={cx("question-column__timer-icon")} />
                {
                  Math.floor(currentQuestion.time / 1000) // Display time in seconds
                }
              </div>
            </div>
          </div>
        </div>

        <div className={cx("answer-column")}>
          <div className={cx("answer-column__container")}>
            {currentQuestion.selectedOption !== null ||
            currentQuestion.time === 0 ? (
              (() => {
                const hasSelectedCorrectAnswer =
                  currentQuestion.selectedOption ===
                  currentQuestion.correctAnswer;

                return (
                  <div
                    className={cx("question-feedback", {
                      "question-feedback--hidden":
                        currentQuestion.isColumnsHidden,
                    })}
                  >
                    <h4
                      className={cx("question-feedback__title", {
                        "question-feedback__title--correct":
                          hasSelectedCorrectAnswer,
                      })}
                    >
                      {(() => {
                        const iconProps = {
                          className: cx("question-feedback__icon"),
                        };
                        if (hasSelectedCorrectAnswer) {
                          return (
                            <>
                              <QuizIconTick {...iconProps} />{" "}
                              {questionsText.labelCorrect}
                            </>
                          );
                        } else if (currentQuestion.time === 0) {
                          return (
                            <>
                              <QuizIconClock {...iconProps} />
                              {questionsText.labelTimesUp}
                            </>
                          );
                        } else {
                          return (
                            <>
                              <QuizIconSadFace {...iconProps} />
                              {questionsText.labelWrong}
                            </>
                          );
                        }
                      })()}
                    </h4>
                    <div className={cx("question-feedback__panel")}>
                      <h5 className={cx("question-feedback__panel-title")}>
                        {questionsText.answerKicker}
                      </h5>
                      <div className={cx("question-feedback__panel-content")}>
                        {currentQuestion.correctAnswerImage?.url &&
                          (() => {
                            const imageProps = {
                              className: cx("question-feedback__panel-image"),
                              alt:
                                currentQuestion.correctAnswerImage
                                  .description || "",
                              height: undefined,
                              width: undefined,
                            };

                            switch (imageSource) {
                              case "contentful": {
                                return (
                                  <img
                                    {...imageProps}
                                    src={getContentfulImageUrl(
                                      currentQuestion.correctAnswerImage.url,
                                      {
                                        width: 300,
                                        height: undefined,
                                      },
                                    )}
                                  />
                                );
                              }
                              case "storyblok": {
                                return (
                                  <img
                                    {...imageProps}
                                    src={getStoryblokImageUrl(
                                      currentQuestion.correctAnswerImage.url,
                                      {
                                        width: 300,
                                        height: undefined,
                                      },
                                    )}
                                  />
                                );
                              }
                              default: {
                                return (
                                  <img
                                    {...imageProps}
                                    src={currentQuestion.correctAnswerImage.url}
                                  />
                                );
                              }
                            }
                          })()}

                        <div>
                          <h5 className={cx("question-feedback__panel-answer")}>
                            {currentQuestion.correctAnswer}
                          </h5>
                          <p className={cx("question-feedback__panel-text")}>
                            {currentQuestion.correctAnswerText}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={cx("question-feedback__next-block")}>
                      <button
                        className={cx("question-feedback__button")}
                        onClick={onClickNext}
                      >
                        {questionsText.nextButtonText}
                      </button>
                      <a
                        className={cx("question-feedback__restart")}
                        href={questionsText.restartLinkUrl ?? undefined}
                      >
                        {questionsText.restartLinkText}
                      </a>
                    </div>
                  </div>
                );
              })()
            ) : (
              <div className={cx("answer-options")}>
                {currentQuestion.options.map((option, index) => (
                  <div
                    key={index}
                    className={cx("answer-option", {
                      "answer-option--selected":
                        currentQuestion.selectedOption === option,
                      "answer-option--hidden":
                        currentQuestion.isAnswerOptionsHidden,
                      "answer-option--visible":
                        !currentQuestion.isAnswerOptionsHidden,
                    })}
                    onClick={() => onClickOption(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
