export function QuizIconClock({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <path d="M14.37 15.43l-3-3a.49.49 0 0 1-.15-.36V7.5a.5.5 0 0 1 .5-.5h.5a.5.5 0 0 1 .5.5v4.19l2.68 2.68a.51.51 0 0 1 0 .71l-.36.35a.48.48 0 0 1-.67 0z" />
      <path d="M12 5a7 7 0 1 1-7 7 7 7 0 0 1 7-7m0-1.5a8.5 8.5 0 1 0 8.5 8.5A8.51 8.51 0 0 0 12 3.5z" />
    </svg>
  );
}
