export function QuizIconTick({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#db0a40"
      className={className}
    >
      <path d="M11 14.72l-2.88-2.84a.48.48 0 0 1 0-.7l.35-.36a.51.51 0 0 1 .71 0l2.07 2.07 8.17-9.71a.51.51 0 0 1 .71-.06l.38.32a.49.49 0 0 1 .06.7l-8.88 10.55a.49.49 0 0 1-.69.03z" />
      <path d="M11 14.72l-2.88-2.84a.48.48 0 0 1 0-.7l.35-.36a.51.51 0 0 1 .71 0l2.07 2.07 8.17-9.71a.51.51 0 0 1 .71-.06l.38.32a.49.49 0 0 1 .06.7l-8.88 10.55a.49.49 0 0 1-.69.03z" />
      <path d="M18.71 10a6.94 6.94 0 0 1 0 3.89 7 7 0 1 1-4.37-8.5.5.5 0 0 0 .66-.21l.23-.47A.49.49 0 0 0 15 4a6.93 6.93 0 0 0-2.38-.51A8.5 8.5 0 1 0 13 20.44a8.56 8.56 0 0 0 7.39-7.13 8.87 8.87 0 0 0 .1-1.44 6.69 6.69 0 0 0-.39-2.39.5.5 0 0 0-.7-.29l-.4.24a.49.49 0 0 0-.29.57z" />
    </svg>
  );
}
