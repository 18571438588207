import classNames from "classnames/bind";
import styles from "./QuizEndScreen.module.css";
import { QuizIconTick } from "./QuizIconTick";
import { QuizIconSadFace } from "./QuizIconSadFace";
import { EndScreenText } from "./Quiz.types";
import { getImageSource } from "../../../helpers/getImageSource";
import { getStoryblokVideoUrl } from "../../../helpers/images/getStoryblokVideoUrl";
import { getContentfulVideoUrl } from "../../../helpers/getContentfulVideoUrl";

const cx = classNames.bind(styles);

const totalTimeInSeconds = (time: number) => {
  const seconds = Math.floor((time / 1000) % 60);
  return seconds < 10 ? "0" + seconds : seconds.toString();
};

const totalTimeInMinutes = (time: number) => {
  const minutes = Math.floor(time / 60000);
  return minutes.toString();
};

export const QuizEndScreen = ({
  score,
  totalTime,
  endScreenText,
}: {
  score: number;
  totalTime: number;
  endScreenText: EndScreenText;
}) => {
  const assetSource = endScreenText.videoUrl
    ? getImageSource(endScreenText.videoUrl)
    : null;

  const scoreRank: "good" | "ok" | "bad" = (() => {
    if (score === 100) {
      return "good";
    } else if (score >= 60) {
      return "ok";
    } else {
      return "bad";
    }
  })();

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <div className={cx("result")}>
          <div className={cx("panel")} data-rank={scoreRank}>
            <span className={cx("score")}>
              {(() => {
                const iconProps = {
                  className: cx("icon"),
                };
                if (scoreRank === "good" || scoreRank === "ok") {
                  return <QuizIconTick {...iconProps} />;
                } else {
                  return <QuizIconSadFace {...iconProps} />;
                }
              })()}
              {`${score}%`}
            </span>
            <span className={cx("your-time")}>
              {endScreenText.totalTimeLabel}: {totalTimeInMinutes(totalTime)}:
              {totalTimeInSeconds(totalTime)}
            </span>
          </div>
          <h3 className={cx("completion-text")}>
            {(() => {
              switch (scoreRank) {
                case "good":
                  return endScreenText.completionGood;
                case "ok":
                  return endScreenText.completionOk;
                default:
                  return endScreenText.completionBad;
              }
            })()}
            <br />
            {endScreenText.completionText}
          </h3>
          <div className={cx("button-block")}>
            <a
              className={cx("button")}
              href={endScreenText.moreButtonUrl ?? undefined}
            >
              {endScreenText.moreButtonText}
            </a>
            <a
              className={cx("button", "button--outline")}
              href={endScreenText.retakeButtonUrl ?? undefined}
            >
              {endScreenText.retakeButtonText}
            </a>
          </div>
        </div>
        {endScreenText.videoUrl &&
          (() => {
            const videoProps = {
              className: cx("video"),
              preload: "auto",
              autoPlay: true,
              playsInline: true,
              muted: true,
              loop: true,
            };

            switch (assetSource) {
              case "contentful": {
                return (
                  <video
                    {...videoProps}
                    src={getContentfulVideoUrl(endScreenText.videoUrl)}
                  />
                );
              }
              case "storyblok": {
                return (
                  <video
                    {...videoProps}
                    src={getStoryblokVideoUrl(endScreenText.videoUrl)}
                  />
                );
              }
              default: {
                return <video {...videoProps} src={endScreenText.videoUrl} />;
              }
            }
          })()}
      </div>
    </div>
  );
};
