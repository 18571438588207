export function QuizIconSadFace({ className }: { className?: string }) {
  return (
    <svg
      width="150px"
      height="150px"
      viewBox="0 0 150 150"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>fail</title>
      <desc>Created with Sketch.</desc>
      <g id="fail" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Group"
          transform="translate(21.153846, 21.153846)"
          fill="#000000"
          fillRule="nonzero"
        >
          <ellipse
            id="Oval"
            cx="39.8588235"
            cy="36.9148499"
            rx="7.18371041"
            ry="7.25627314"
          />
          <ellipse
            id="Oval"
            cx="68.5936652"
            cy="36.9148499"
            rx="7.18371041"
            ry="7.25627314"
          />
          <path
            d="M36.6022081,75.6149733 C37.5927858,75.5944556 38.4685537,74.9598411 38.8052127,74.0185932 C41.3346157,67.6571499 47.4394537,63.4885398 54.2262443,63.4885398 C61.013035,63.4885398 67.1178729,67.6571499 69.647276,74.0185932 C69.983935,74.9598411 70.8597029,75.5944556 71.8502805,75.6149733 L75.1068959,75.6149733 C75.7400672,75.6689467 76.3569711,75.3940619 76.7443947,74.8853239 C77.1317426,74.3765859 77.23661,73.7037895 77.022552,73.0994652 C73.8388313,63.0977107 64.6272286,56.3156577 54.2262443,56.3156577 C43.8252601,56.3156577 34.6136574,63.0977107 31.4299367,73.0994652 C31.2158787,73.7037895 31.3207461,74.3765859 31.708094,74.8853239 C32.0955176,75.3940619 32.7124215,75.6689467 33.3455928,75.6149733 L36.6022081,75.6149733 Z"
            id="Path"
          />
          <path
            d="M53.8461538,9.50226244 C78.3366088,9.50226244 98.1900452,29.3556989 98.1900452,53.8461538 C98.1900452,78.3366088 78.3366088,98.1900452 53.8461538,98.1900452 C29.3556989,98.1900452 9.50226244,78.3366088 9.50226244,53.8461538 C9.50226244,29.3556989 29.3556989,9.50226244 53.8461538,9.50226244 L53.8461538,9.50226244 Z M53.8461538,0 C24.1077442,0 0,24.1077442 0,53.8461538 C0,83.5845635 24.1077442,107.692308 53.8461538,107.692308 C83.5845635,107.692308 107.692308,83.5845635 107.692308,53.8461538 C107.6574,24.1222155 83.5700922,0.0349077734 53.8461538,0 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
}
