"use client";

import React, { useReducer, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./SustainabilityQuiz.module.css";
import { dataLayer } from "../../../helpers/dataLayer";
import {
  EndScreenText,
  Message,
  Model,
  QuestionBlock,
  QuestionItem,
  QuestionsText,
} from "./Quiz.types";
import { QuizEndScreen } from "./QuizEndScreen";
import { QuizQuestionScreen } from "./QuizQuestionScreen";
import { useLocaleStringContext } from "../../../helpers/LocaleStringContext";

const cx = classNames.bind(styles);

function init({ questionsBlocks }: { questionsBlocks: QuestionItem[] }): Model {
  const allQuestionBlock =
    questionsBlocks.map<QuestionBlock>((item) => ({
      question: item.question,
      options: [
        item.option1 ?? "",
        item.option2 ?? "",
        item.option3 ?? "",
        item.option4 ?? "",
      ],
      correctAnswer: item.answerTitle,
      correctAnswerText: item.answerDescription,
      correctAnswerImage: item.answerImage,
      selectedOption: null,
      time: 30000,
      isColumnsHidden: false,
      isAnswerOptionsHidden: false,
    })) ?? [];

  if (allQuestionBlock.length > 0) {
    const [first, ...rest] = allQuestionBlock;
    return {
      currentQuestion: first,
      previousQuestions: [],
      nextQuestions: rest,
      endScreenIsShown: false,
      pendingSetActiveOption: null,
      pendingSetCurrentQuestion: false,
    };
  } else {
    throw new Error("No questions provided");
  }
}

function reducer(model: Model, message: Message): Model {
  switch (message.type) {
    case "UserClickedOnOption": {
      return {
        ...model,
        currentQuestion: {
          ...model.currentQuestion,
          isAnswerOptionsHidden: true,
        },
        pendingSetActiveOption: message.option,
      };
    }
    case "SetActiveOption": {
      return {
        ...model,
        currentQuestion: {
          ...model.currentQuestion,
          selectedOption: message.option,
        },
        pendingSetActiveOption: null,
      };
    }
    case "UserClickedOnNext": {
      return {
        ...model,
        currentQuestion: {
          ...model.currentQuestion,
          isColumnsHidden: true,
        },
        pendingSetCurrentQuestion: true,
      };
    }
    case "SetCurrentQuestion": {
      if (model.nextQuestions.length > 0) {
        const [next, ...rest] = model.nextQuestions;

        return {
          ...model,
          currentQuestion: next,
          previousQuestions: [
            model.currentQuestion,
            ...model.previousQuestions,
          ],
          nextQuestions: rest,
          pendingSetCurrentQuestion: false,
        };
      } else {
        return {
          ...model,
          previousQuestions: [
            model.currentQuestion,
            ...model.previousQuestions,
          ],
          endScreenIsShown: true,
          pendingSetCurrentQuestion: false,
        };
      }
    }
    case "Tick": {
      if (
        model.currentQuestion.selectedOption == null &&
        model.currentQuestion.time > 0
      ) {
        return {
          ...model,
          currentQuestion: {
            ...model.currentQuestion,
            time: model.currentQuestion.time - 1000,
          },
        };
      } else {
        return model;
      }
    }
    case "NoOp": {
      return model;
    }
    default: {
      return model;
    }
  }
}

function calculateScore(model: Model): number {
  const accumulateScore = model.previousQuestions.reduce(
    (acc, questionBlock) =>
      acc +
      (questionBlock.selectedOption === questionBlock.correctAnswer ? 1 : 0),
    0,
  );

  const totalQuestions = model.previousQuestions.length;
  return totalQuestions > 0
    ? Math.ceil((accumulateScore / totalQuestions) * 100)
    : 0;
}

export function SustainabilityQuiz({
  questionsBlocks,
  questionsText,
  endScreenText,
}: {
  questionsBlocks: QuestionItem[];
  questionsText: QuestionsText;
  endScreenText: EndScreenText;
}) {
  const localeString = useLocaleStringContext();

  const [state, dispatch] = useReducer(
    reducer,
    { questionsBlocks, localeString },
    init,
  );

  const score = calculateScore(state);
  const totalQuestionCount =
    1 + state.nextQuestions.length + state.previousQuestions.length;
  const currentQuestionNumber = totalQuestionCount - state.nextQuestions.length;

  const totalTime = state.previousQuestions.reduce(
    (acc, question) => acc + (30000 - question.time),
    0,
  );

  useEffect(() => {
    dataLayer.push({
      event: "virtPath",
      virtPath: `${localeString}-quiz-start`,
    });
  }, [localeString]);

  const virtPath = state.endScreenIsShown
    ? `${localeString}-quiz-score-${score}%`
    : `${localeString}-quiz-${currentQuestionNumber}-${/* Slugified question: */
      state.currentQuestion.question?.toLowerCase().replaceAll(" ", "-")}`;

  useEffect(() => {
    dataLayer.push({
      event: "virtPath",
      virtPath,
    });
  }, [virtPath]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch({ type: "Tick" });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (state.pendingSetActiveOption != null) {
      const option = state.pendingSetActiveOption;
      const timeoutId = setTimeout(() => {
        dispatch({ type: "SetActiveOption", option });
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [state.pendingSetActiveOption]);

  useEffect(() => {
    if (state.pendingSetCurrentQuestion) {
      const timeoutId = setTimeout(() => {
        dispatch({ type: "SetCurrentQuestion" });
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [state.pendingSetCurrentQuestion]);

  return (
    <section className={cx("container")}>
      {state.endScreenIsShown ? (
        <QuizEndScreen
          endScreenText={endScreenText}
          score={score}
          totalTime={totalTime}
        />
      ) : (
        <QuizQuestionScreen
          currentQuestion={state.currentQuestion}
          currentQuestionNumber={currentQuestionNumber}
          questionsText={questionsText}
          totalQuestionCount={totalQuestionCount}
          onClickOption={(option) =>
            dispatch({
              type: "UserClickedOnOption",
              option,
            })
          }
          onClickNext={() => {
            dispatch({ type: "UserClickedOnNext" });
          }}
        />
      )}
    </section>
  );
}
